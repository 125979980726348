function App() {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl">
                        <div
                            className="info-section animate__animated animate__fadeInDown animate__slower">
                            <h1 className="name_farshid">Mahdi
                                Farshid</h1>
                            <br></br>
                            <h2><i className="fab fa-laravel"
                                   style={{"color": "#F30F18"}}/><i className="fab fa-react"
                                   style={{"color": "#00d8fe"}}/>Fullstack
                                Developer</h2>
                            <h2><i className="fab fa-linux"
                                   style={{"color": "#000"}}/>Linux
                                Server Management</h2>
                            <h2><i className="fa fa-business-time"
                                   style={{"color": "#000"}}/>5+ Experience</h2>

                        </div>
                        <div
                            className="social-links animate__animated animate__fadeInUp animate__slow animate__delay-1s">
                            <a href="https://t.me/mahdi77230"
                               target="_blank"><i id="telegram"
                                                  className="fab fa-telegram"/></a>
                            <a href="https://github.com/mahdifarshid"
                               target="_blank"><i id="github"
                                                  className="fab fa-github"/></a>
                            {/*<a href="https://stackoverflow.com/users/11340265/mahdi-farshid"*/}
                            {/*   target="_blank"><i id="stackoverflow"*/}
                            {/*                      className="fab fa-stack-overflow"/></a>*/}
                            {/*<a href="https://twitter.com/farshid7720"*/}
                            {/*   target="_blank"><i id="twitter"*/}
                            {/*                      className="fab fa-twitter"/></a>*/}
                            <a href="https://www.linkedin.com/in/mahdifarshid"
                               target="_blank"><i id="linkedin"
                                                  className="fab fa-linkedin"/></a>
                            {/*<a href="https://www.youtube.com/channel/UCwa0pBKFd0e-j2-ftJiTZ4g"*/}
                            {/*   target="_blank"><i id="youtube"*/}
                            {/*                      className="fab fa-youtube"/></a>*/}
                            <a href="mailto:mahdifarshid2015@gmail.com"
                               target="_blank"><i id="email"
                                                  className="fas fa-envelope"/></a>
                            <a href="MahdiFarshid_FullStack.pdf"
                               target="_blank"><i id="email"
                                                  className="fas fa-download"/>My
                                Resume</a>
                        </div>
                        <div className="footer">
                            <div style={{
                                "font-size": "12px",
                                "position": "relative",
                                "z-index": "1000"
                            }}>
                            </div>
                        </div>
                    </div>
                    <div id="farshid" className="col-xl-5">
                        <div
                            className="farshid_img animate__animated animate__fadeIn animate__slower animate__delay-1s"/>
                    </div>
                    {/*<div id="mahyar" className="col-xl-6">*/}
                    {/*    <div className="row">*/}
                    {/*        <div*/}
                    {/*            className="col-xl-4 mahyar_img animate__animated animate__fadeIn animate__slower animate__delay-1s"/>*/}
                    {/*        <h1 className="name_mahyar col-xl-8 ">Mahyar*/}
                    {/*            Farshid</h1>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default App;
